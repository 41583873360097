import styled from "styled-components"

import { color } from "../../../atoms/colors"

export const Wrapper = styled.section`
  h1 {
    font-weight: 900;
    font-size: 48px;
    line-height: 60px;
    text-align: center;
    letter-spacing: -0.02em;
    color: ${color.oxfordBlue[900]};
    max-width: 1170px;
    margin: auto;
    @media (max-width: 991.98px) {
      max-width: 770px;
    }
    @media (max-width: 767.98px) {
      max-width: 506px;
      font-size: 36px;
      line-height: 44px;
    }
    @media (max-width: 575.98px) {
      font-size: 24px;
      line-height: 32px;
    }
  }
  p {
    max-width: 770px;
    font-weight: 350;
    font-size: 20px;
    line-height: 30px;
    color: ${color.copy[700]};
    margin: auto;
    padding-top: 16px;
    @media (max-width: 767.98px) {
      max-width: 506px;
    }
    @media (max-width: 575.98px) {
      font-size: 16px;
      line-height: 24px;
    }
  }
  .heading {
    padding: 96px 15px;
    @media (max-width: 991.98px) {
      padding: 56px 15px;
    }
    @media (max-width: 767.98px) {
      padding: 40px 15px;
    }
  }
`

export const ImageContainer = styled.div`
  padding: 40px 0px;
`
export const StyledImage = styled.img`
  height: auto;
  width: 100%;
  max-width: 1970px;
`
