import { StaticImage } from "gatsby-plugin-image"
import React from "react"
import { Container, Row, Col } from "react-bootstrap"

import { Wrapper } from "../../styles/Components/SingleInstance/ProductUiImg"

const ProductUiImg = () => (
  <Wrapper>
    <Container fluid>
      <Row className="justify-content-center text-center">
        <Col className="heading" sm={12}>
          <h1>Drive more revenue with Chat Commerce</h1>
          <p>
            Create personalized interactions with your customers at scale.
            Engage with your customers through messaging, automation, live agent
            interactions and payments, all through one unified experience.
          </p>
        </Col>
        <Col xs={12}>
          <StaticImage
            src="../../images/product-ui.png"
            alt="Clickatell - Product UI"
          />
        </Col>
      </Row>
    </Container>
  </Wrapper>
)

export default ProductUiImg
